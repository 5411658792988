import React from "react";
// import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const MetaTags = ({title, description, keywords, author, type, image=process.env.REACT_APP_META_IMAGE}) => {
    const location = useLocation();

    let image_abs_url = "";
    if(image) {
        image_abs_url = image.indexOf("http") >= 0 ? image : window.location.origin + image;
    }

    const moveMetaTagsToTop = () => {
        const head = document.head;
        const metaTags = document.querySelectorAll("head > meta");

        // Move each meta tag to the start of the head
        metaTags.forEach((meta) => {
            head.insertBefore(meta, head.firstChild);
        });
    };

    return (
        <>
            <Helmet onChangeClientState={moveMetaTagsToTop}>
                <title>{title ? title : process.env.REACT_APP_PROJECT_NAME + ' - ' + process.env.REACT_APP_META_TITLE}</title>
                <meta name="description" content={description ? description : process.env.REACT_APP_META_DESCRIPTION} />
                <meta name="keywords" content={keywords ? keywords : process.env.REACT_APP_META_KEYWORDS} />
                <meta name="author" content={author ? author : process.env.REACT_APP_META_AUTHOR} />
                <meta name="author" content={author ? author : process.env.REACT_APP_META_AUTHOR} />
                <meta property="og:title" content={title ? title : process.env.REACT_APP_META_TITLE} />
                <meta property="og:description" content={description ? description : process.env.REACT_APP_META_DESCRIPTION} />
                <meta property="og:image" content={image_abs_url} />
                <meta property="og:type" content={type ? type : "website"} />
                <meta property="og:url" content={window.location.origin + location.pathname} />
                <meta name="twitter:creator" content={author ? author : process.env.REACT_APP_META_AUTHOR} />
                <meta name="twitter:title" content={title ? title : process.env.REACT_APP_META_TITLE} />
                <meta name="twitter:description" content={description ? description : process.env.REACT_APP_META_DESCRIPTION} />
                <meta name="twitter:image" content={image_abs_url} />
                <meta name="twitter:card" content={type ? type : "website"} />
            </Helmet>
        </>
    )
}

MetaTags.prototype = {
    title: PropTypes.string,  // title: PropTypes.string.isRequired,
    description: PropTypes.string,
    keywords: PropTypes.string,
    author: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string
}

export default MetaTags;