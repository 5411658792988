import React from "react";
import MetaTags from "../components/Blog/MetaTags";

export default function Home() {
    return (
        <>
            <MetaTags />

            <section data-id="home" className="animated-section section-active">
                <div className="section-content vcentered">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="title-block">
                                <h1>Iman Karimi</h1>
                                <div className="owl-carousel text-rotation">
                                    <div className="item">
                                        <div className="sp-subtitle">{process.env.REACT_APP_META_TITLE}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}