import React from "react";

export default function Post({post, idx}) {
    return (
        <div className={`item post-${idx}`}>
            <div className="blog-card">
                <div className="media-block">
                    <div className="category">
                        <a href="#" title={`View all posts in ${post.category}`}>{post.category}</a>
                    </div>

                    <a href={`/blog/${post.slug}`}>
                        <img
                            src={post.image}
                            className="size-blog-masonry-image-two-c"
                            alt={post.title}
                            title={post.title}
                        />
                        <div className="mask"></div>
                    </a>
                </div>
                <div className="post-info">
                    <div className="post-date">{post.created_time}</div>
                    <a href={`/blog/${post.slug}`}>
                        <h4 className="blog-item-title">{post.title}</h4>
                    </a>
                </div>
            </div>
        </div>
    )
}