import React from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import MetaTags from "../components/Blog/MetaTags";

export default function Resume() {
    return (
        <>
            <MetaTags title={process.env.REACT_APP_PROJECT_NAME + " - Resume"} />

            <PerfectScrollbar>
                <section data-id="resume" className="animated-section section-active">
                    <div className="page-title">
                        <h1>Resume</h1>
                    </div>

                    <div className="section-content">
                        <div className="row">
                            <div className="col-xs-12 col-sm-7">
                                <div className="block-title">
                                    <h3>Education</h3>
                                </div>

                                <div className="timeline timeline-second-style clearfix">
                                    <div className="timeline-item clearfix">
                                        <div className="left-part">
                                            <h5 className="item-period">2017</h5>
                                            <span className="item-company">Shafagh University of Nashtarood</span>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="right-part">
                                            <h4 className="item-title">MS. In Artificial Intelligence (AI)</h4>
                                            <p>From 2017 to 2020, I pursued a degree in Artificial Intelligence at
                                                Shafagh Nashtaroud University. I completed my thesis in the medical
                                                field, titled "Detection of Epilepsy Using Hybrid Machine Learning
                                                Methods."</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item clearfix">
                                        <div className="left-part">
                                            <h5 className="item-period">2012</h5>
                                            <span className="item-company">PNU University of Ramsar</span>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="right-part">
                                            <h4 className="item-title">BS. In Software Engineering</h4>
                                            <p>From 2012 to 2017, I pursued a degree in Software Engineering at Payam
                                                Noor University of Ramsar. This period was pivotal in the development of
                                                my programming expertise. Approximately two years into my studies, I
                                                received job offers from several companies in Tehran and commenced my
                                                professional career during this time.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="white-space-50"></div>

                                <div className="block-title">
                                    <h3>Experience</h3>
                                </div>

                                <div className="timeline timeline-second-style clearfix">
                                    <div className="timeline-item clearfix">
                                        <div className="left-part">
                                            <h5 className="item-period">2023 - Current</h5>
                                            <span className="item-company">Phownance</span>
                                            <span className="item-company">USA</span>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="right-part">
                                            <h4 className="item-title">CTO & Python Dev & AI</h4>
                                            <p>Phownance is an ongoing development project aimed at detecting market
                                                movements (including price corrections, support and resistance levels,
                                                and candlestick patterns) in the Forex markets using artificial
                                                intelligence models. Our experiences in this field have been highly
                                                exciting, and this project continues to advance robustly.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item clearfix">
                                        <div className="left-part">
                                            <h5 className="item-period">2019 - Current</h5>
                                            <span className="item-company">ICW</span>
                                            <span className="item-company">USA</span>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="right-part">
                                            <h4 className="item-title">Python Backend Developer & DevOps & CTO</h4>
                                            <p>ICW (I Codes Wholesales) is a marketplace for selling digital gift cards
                                                in bulk. Based in Los Angeles, California. ICW is the only marketplace
                                                in the United States of America that specialized in selling digital and
                                                games gift cards at a wholesale price.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item clearfix">
                                        <div className="left-part">
                                            <h5 className="item-period">2020 - 2022</h5>
                                            <span className="item-company">EZPIN</span>
                                            <span className="item-company">Turkey</span>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="right-part">
                                            <h4 className="item-title">Python Backend Developer</h4>
                                            <p>EZ PIN was created with a singular focus: To enable and leverage gift
                                                cards as powerful branded payment instruments to drive and grow revenues
                                                in-store, online, in digital wallets, via social media, in apps, and in
                                                games! Our global distribution channel and our comprehensive
                                                distribution panel enabled brands to reach to thousands of clients
                                                across the globe.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item clearfix">
                                        <div className="left-part">
                                            <h5 className="item-period">2020 - 2022</h5>
                                            <span className="item-company">AppSeed</span>
                                            <span className="item-company">Romania</span>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="right-part">
                                            <h4 className="item-title">Python Backend Developer</h4>
                                            <p>AppSeed basically aims to cut the manual work usually involved in web
                                                development using automation tools and boilerplate code. When a new
                                                project is started, the developer does this repetitive work over and
                                                over: Integrate the UI (this takes days to extract the layouts,
                                                components..), Code the basic modules, using the best practices, Prepare
                                                the app with necessary scripts and configuration to be deployed LIVE.
                                                The value-added provided by AppSeed it’s a sum-up of all those steps.
                                                The flat, lifeless UI is automatically processed using an HTML Parser
                                                (in-house developed tool) that prepares the UI files to become
                                                production-ready for many technologies: PHP, Python, React, Vue.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item clearfix">
                                        <div className="left-part">
                                            <h5 className="item-period">2017 - 2022</h5>
                                            <span className="item-company">Yara</span>
                                            <span className="item-company">Iran</span>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="right-part">
                                            <h4 className="item-title">PHP & Python Backend Developer</h4>
                                            <p>Yara Group started its activity in 2003 and is considered one of the
                                                leading companies in the field of production and development of games
                                                and mobile applications. The Yara collection has produced more than 30
                                                applications, among which we can mention the games Khoros Jangi
                                                (Rooster), Zero to Hundred, Footcardia, as well as Zaafron, Bank
                                                Branches, Fajr Festival, Pishvaz Irancell, Tapligh, and Digitoon.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item clearfix">
                                        <div className="left-part">
                                            <h5 className="item-period">2016 - 2017</h5>
                                            <span className="item-company">Homatec</span>
                                            <span className="item-company">Iran</span>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="right-part">
                                            <h4 className="item-title">PHP Backend Developer</h4>
                                            <p>Homatec is a company that specializes in web-related services. One of
                                                their notable projects is the Yaghoot Rooyan web application. Yaghoot
                                                Rooyan International Group was founded in 1991 with a mission to import
                                                food supplements, pharmaceuticals, medical equipment, and herbal
                                                hygienic and cosmetic products for public health and beauty care.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item clearfix">
                                        <div className="left-part">
                                            <h5 className="item-period">2015 - 2016</h5>
                                            <span className="item-company">SRICO</span>
                                            <span className="item-company">Iran</span>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="right-part">
                                            <h4 className="item-title">PHP Backend Developer</h4>
                                            <p>SRICO is a company with a demonstrated history of working in the
                                                web-related services and applications industry, among which we can
                                                mention the Khabargram (Khabargram is a news crawler that brings
                                                together all the news of the day and of the moment to all the important
                                                news agencies and presents them to the enthusiasts. The feature of this
                                                software is that you do not need to spend your time surfing on different
                                                sites.), Exchange brokerage group (The web-based brokerage software is
                                                one of the most successful web application in the field of stock
                                                exchange, which allows its users to analyze and forecast the results of
                                                the stock exchange.), Distribution and allocation of water resources
                                                (The system supporting the distribution and allocation of water
                                                resources in Qazvin Plain), and Dast2.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-xs-12 col-sm-5">
                                <div className="block-title">
                                    <h3>Coding <span>Skills</span></h3>
                                </div>

                                <div className="skills-info skills-second-style">
                                    <div className="skill clearfix">
                                        <h4>Python</h4>
                                        <div className="skill-value">100%</div>
                                    </div>
                                    <div className="skill-container skill-7">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>PHP</h4>
                                        <div className="skill-value">95%</div>
                                    </div>
                                    <div className="skill-container skill-1">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>Javascript</h4>
                                        <div className="skill-value">80%</div>
                                    </div>
                                    <div className="skill-container skill-3">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>SQL (MySQL, PostgreSQL, MSSQL, Redis)</h4>
                                        <div className="skill-value">85%</div>
                                    </div>
                                    <div className="skill-container skill-4">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>HTML/CSS</h4>
                                        <div className="skill-value">100%</div>
                                    </div>
                                    <div className="skill-container skill-7">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>MQL4 & MQL5</h4>
                                        <div className="skill-value">95%</div>
                                    </div>
                                    <div className="skill-container skill-5">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>Linux Bash</h4>
                                        <div className="skill-value">60%</div>
                                    </div>
                                    <div className="skill-container skill-2">
                                        <div className="skill-percentage"></div>
                                    </div>
                                </div>

                                <div className="white-space-10"></div>

                                <div className="block-title">
                                    <h3>Framework <span>Skills</span></h3>
                                </div>

                                <div className="skills-info skills-second-style">
                                    <div className="skill clearfix">
                                        <h4>Django</h4>
                                        <div className="skill-value">100%</div>
                                    </div>
                                    <div className="skill-container skill-7">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>Flask</h4>
                                        <div className="skill-value">95%</div>
                                    </div>
                                    <div className="skill-container skill-5">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>Laravel</h4>
                                        <div className="skill-value">95%</div>
                                    </div>
                                    <div className="skill-container skill-5">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>Lumen</h4>
                                        <div className="skill-value">95%</div>
                                    </div>
                                    <div className="skill-container skill-5">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>React</h4>
                                        <div className="skill-value">60%</div>
                                    </div>
                                    <div className="skill-container skill-2">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>jQuery</h4>
                                        <div className="skill-value">85%</div>
                                    </div>
                                    <div className="skill-container skill-4">
                                        <div className="skill-percentage"></div>
                                    </div>

                                    <div className="skill clearfix">
                                        <h4>MT4 & MT5</h4>
                                        <div className="skill-value">95%</div>
                                    </div>
                                    <div className="skill-container skill-5">
                                        <div className="skill-percentage"></div>
                                    </div>
                                </div>

                                <div className="white-space-10"></div>

                                <div className="block-title">
                                    <h3>Knowledges</h3>
                                </div>

                                <ul className="knowledges">
                                    <li>Image Processing</li>
                                    <li>Machine Learning</li>
                                    <li>Data Mining</li>
                                    <li>REST</li>
                                    <li>GraphQL</li>
                                    <li>DevOps</li>
                                    <li>Linux</li>
                                    <li>AWS</li>
                                    <li>DigitalOcean</li>
                                    <li>CloudFlare</li>
                                    <li>CI/CD</li>
                                    <li>Git Flow</li>
                                    <li>Docker & Docker Compose</li>
                                    <li>Scrum</li>
                                    <li>Agile</li>
                                    <li>Figma</li>
                                    <li>Adobe XD</li>
                                    <li>Trade</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </PerfectScrollbar>
        </>
    );
}