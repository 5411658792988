import React from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Post from "../components/Blog/Post";
import MetaTags from "../components/Blog/MetaTags";
import posts from '../assets/blog/posts.json';

export default function Blog() {
    return (
        <>
            <MetaTags title={process.env.REACT_APP_PROJECT_NAME + " - Blog"} />

            <PerfectScrollbar>
                <section data-id="blog" className="animated-section section-active">
                    <div className="page-title">
                        <h1>Blog</h1>
                    </div>

                    <div className="section-content">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <div className="blog-masonry two-columns clearfix">
                                    {!posts && <p>Posts Not Found!</p>}

                                    {posts && posts.map((post, idx) => (
                                        <Post post={post} idx={idx} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PerfectScrollbar>
        </>
    );
}