import React, {useState, useEffect} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {axiosTelegramPrivate} from "../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MetaTags from "../components/Blog/MetaTags";

const FULL_NAME_REGEX = /^[a-zA-Z]{2,10} [a-zA-Z]{2,10}$/;
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const SUBJECT_REGEX = /^[a-zA-Z0-9 ]{15,50}$/;
const MESSAGE_REGEX = /^[a-zA-Z0-9.,?!()$& ]{50,100}$/;

export default function Contact() {
    const [fullName, setFullName] = useState('');
    const [isValidFullName, setIsValidFullName] = useState(false);
    const [errorFullName, setErrorFullName] = useState('');

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [errorEmail, setErrorEmail] = useState('');

    const [subject, setSubject] = useState('');
    const [isValidSubject, setIsValidSubject] = useState(false);
    const [errorSubject, setErrorSubject] = useState('');

    const [message, setMessage] = useState('');
    const [isValidMessage, setIsValidMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [captchaValue, setCaptchaValue] = useState(null);

    useEffect(() => {
        const isValid = FULL_NAME_REGEX.test(fullName);
        setIsValidFullName(isValid);
        if(!isValid) setErrorFullName('Please enter your full name (first & last name).');
    }, [fullName])

    useEffect(() => {
        const isValid = EMAIL_REGEX.test(email);
        setIsValidEmail(isValid);
        if(!isValid) setErrorEmail('Please enter your valid E-mail');
    }, [email])

    useEffect(() => {
        const isValid = SUBJECT_REGEX.test(subject);
        setIsValidSubject(isValid);
        if(!isValid) setErrorSubject('Please enter a valid subject');
    }, [subject])

    useEffect(() => {
        const isValid = MESSAGE_REGEX.test(message);
        setIsValidMessage(isValid);
        if(!isValid) setErrorMessage('Please enter a valid message');
    }, [message])

    const sendMessage = async (e) => {
        e.preventDefault();

        const telegramMsg = `<b>**${subject}**</b>\n\n<b>Full Name: </b><i>${fullName}</i>\n<b>Email: </b><i>${email}</i>\n\n<b>Message: </b><i>${message}</i>`

        try {
            const response = await axiosTelegramPrivate.post(
                '/sendMessage',
                JSON.stringify({
                    chat_id: process.env.REACT_APP_TELEGRAM_BOT_CHANNEL_ID,
                    parse_mode: 'HTML',
                    text: telegramMsg
                }),
                {
                    headers: {'Content-Type': 'application/json'}
                }
            );

            if(response.data.ok) {
                setFullName('');
                setEmail('');
                setSubject('');
                setMessage('');
                toast.success("Your message has been successfully delivered. I will be in touch with you shortly :)");
            }
        } catch (error) {
            if (!error?.response) {
                toast.error("No Server Response. Please try again later.");
            } else {
                toast.error(error?.response?.data?.detail);
            }
        }
    }

    return (
        <>
            <MetaTags title={process.env.REACT_APP_PROJECT_NAME + " - Contact Me"} />

            <ToastContainer />

            <section data-id="contact" className="animated-section section-active">
                <div className="page-title">
                    <h1>Contact</h1>
                </div>

                <div className="section-content">
                    <div className="row">
                        <div className="col-xs-12 col-sm-4">
                            <div className="lm-info-block gray-default">
                                <i className="lnr lnr-map-marker"></i>
                                <h4>Iran, Tehran</h4>
                                <span className="lm-info-block-value"></span>
                                <span className="lm-info-block-text"></span>
                            </div>

                            <div className="lm-info-block gray-default">
                                <i className="lnr lnr-phone-handset"></i>
                                <h4>+98(937) 597 9955</h4>
                                <span className="lm-info-block-value"></span>
                                <span className="lm-info-block-text"></span>
                            </div>

                            <div className="lm-info-block gray-default">
                                <i className="lnr lnr-envelope"></i>
                                <h4>
                                    <a href="mailto:imankarimi.mail@gmail.com">
                                        imankarimi.mail@gmail.com
                                    </a>
                                </h4>
                                <span className="lm-info-block-value"></span>
                                <span className="lm-info-block-text"></span>
                            </div>

                            <div className="lm-info-block gray-default">
                                <i className="lnr lnr-checkmark-circle"></i>
                                <h4>Freelance Available</h4>
                                <span className="lm-info-block-value"></span>
                                <span className="lm-info-block-text"></span>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-8">
                            <div className="block-title">
                                <h3>How Can I <span>Help You?</span></h3>
                            </div>

                            <form id="contact_form" className="contact-form" onSubmit={sendMessage}>
                                <div className="messages"></div>
                                <div className="controls two-columns">
                                    <div className="fields clearfix">
                                        <div className="left-column">
                                            <div className="form-group form-group-with-icon">
                                                <input
                                                    id="full_name"
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    onChange={(e) => setFullName(e.target.value)}
                                                    value={fullName}
                                                />
                                                <label htmlFor="full_name">Full Name</label>
                                                <div className="form-control-border"></div>
                                                {!isValidFullName && errorFullName && fullName &&
                                                    <div className="help-block with-errors">
                                                        {errorFullName}
                                                    </div>
                                                }
                                            </div>

                                            <div className="form-group form-group-with-icon">
                                                <input
                                                    id="email"
                                                    type="email"
                                                    className="form-control"
                                                    required
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                />
                                                <label htmlFor="email">Email Address</label>
                                                <div className="form-control-border"></div>
                                                {!isValidEmail && errorEmail && email &&
                                                    <div className="help-block with-errors">
                                                        {errorEmail}
                                                    </div>
                                                }
                                            </div>

                                            <div className="form-group form-group-with-icon">
                                                <input
                                                    id="subject"
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    onChange={(e) => setSubject(e.target.value)}
                                                    value={subject}
                                                />
                                                <label htmlFor="subject">Subject</label>
                                                <div className="form-control-border"></div>
                                                {!isValidSubject && errorSubject && subject &&
                                                    <div className="help-block with-errors">
                                                        {errorSubject}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="right-column">
                                            <div className="form-group form-group-with-icon">
                                            <textarea
                                                id="message"
                                                name="message"
                                                className="form-control"
                                                rows="7"
                                                required
                                                onChange={(e) => setMessage(e.target.value)}
                                                value={message}
                                            >
                                            </textarea>
                                                <label>Message</label>
                                                <div className="form-control-border"></div>
                                                {!isValidMessage && errorMessage && message &&
                                                    <div className="help-block with-errors">
                                                        {errorMessage}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <ReCAPTCHA
                                        className="g-recaptcha"
                                        theme="dark"
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        onChange={(val) => setCaptchaValue(val)}
                                    />

                                    <button
                                        type="submit"
                                        className="button btn-send"
                                        disabled={!captchaValue || !isValidFullName || !isValidEmail || !isValidSubject || !isValidMessage}
                                    >
                                        Send message
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}