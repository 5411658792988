import React from 'react';
import {useParams} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MetaTags from "../components/Blog/MetaTags";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon
} from 'react-share';

import posts from '../assets/blog/posts.json';


const PostSinglePage = () => {
    const {slug} = useParams(); // Get slug from URL
    const shareUrl = window.location.href;
    const post = posts.find((p) => p.slug === slug);

    if (!post) {
        return <div>Post not found!</div>;
    }

    return (
        <>
            <MetaTags
                title={process.env.REACT_APP_PROJECT_NAME + " - Blog | " + post.title}
                description={post.description}
                keywords={post.keywords}
                type="article"
                image={post.image}
            />

            <PerfectScrollbar>
                <article className="post">
                    <div className="post-thumbnail">
                        <img src={post.image} alt="image"/>
                    </div>

                    <div className="post-content">
                        <header className="entry-header">
                            <div className="entry-meta entry-meta-top">
                                <span><a href="#" rel="category tag">{post.category}</a></span>
                            </div>
                            <h1 className="entry-title">{post.title}</h1>
                        </header>

                        <div className="entry-content">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 post-single-page">
                                    {post.contents.map((content, idx) => (
                                        <div className="col-inner" key={idx} dangerouslySetInnerHTML={{ __html: content }} />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="entry-meta entry-meta-bottom">
                            <div className="date-author">
                                <span className="entry-date">
                                    <a href="#" rel="bookmark">
                                        <i className="far fa-clock"></i>
                                        <span className="entry-date"> {post.created_time}</span>
                                    </a>
                                </span>
                                <span className="author vcard">
                                    <a className="url fn n" href="#" rel="author">
                                        <i className="fas fa-user"></i>
                                        <span> Iman Karimi</span>
                                    </a>
                                </span>
                            </div>

                            <div className="entry-share btn-group share-buttons">
                                <FacebookShareButton url={shareUrl} quote={post.title}>
                                    <FacebookIcon size={30} />
                                </FacebookShareButton>

                                <TwitterShareButton url={shareUrl} title={post.title}>
                                    <TwitterIcon size={30} />
                                </TwitterShareButton>

                                <LinkedinShareButton url={shareUrl} title={post.title}>
                                    <LinkedinIcon size={30} />
                                </LinkedinShareButton>
                            </div>
                        </div>

                        <div className="post-tags">
                            <div className="tags">
                                {post.keywords && post.keywords.split(",").map((tag, idx) => (
                                    <a href="#" rel="tag" key={idx}>{tag}</a>
                                ))}
                            </div>
                        </div>
                    </div>
                </article>
            </PerfectScrollbar>
        </>
    );
};

export default PostSinglePage;
