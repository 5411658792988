import React from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import Header from "../components/Headers/Header";

import Home from "../views/Home";
import About from "../views/About";
import Resume from "../views/Resume";
import Blog from "../views/Blog";
import Contact from "../views/Contact";
import PostSinglePage from "../views/PostSinglePage";


export default function Base() {
    const location = useLocation();
    // Check if the current route starts with '/blog/' and is followed by a slug
    const isSinglePage = /^\/blog\/[a-zA-Z0-9_-]+$/.test(location.pathname);
    const helmetContext = {};

    return (
        <HelmetProvider context={helmetContext}>

            {/*<div className="preloader">*/}
            {/*    <div className="preloader-animation">*/}
            {/*        <div className="preloader-spinner">*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="page-content">
                <Header/>

                <div className={isSinglePage ? "content-area single-page-area" : "content-area"}>
                    <div className={isSinglePage ? "single-page-content" : "animated-sections"}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="about" element={<About/>}/>
                            <Route path="resume" element={<Resume/>}/>
                            <Route path="contact" element={<Contact/>}/>
                            <Route path="blog" element={<Blog/>}/>
                            <Route path="blog/:slug" element={<PostSinglePage/>}/>
                        </Routes>
                    </div>
                </div>

            </div>
        </HelmetProvider>
    );
}