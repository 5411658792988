import {Document, Page, StyleSheet, Text, View, Image, Link} from '@react-pdf/renderer';
import {calculateYearPeriod} from '../../utils/utils'


const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15,
        marginBottom: 15,
    },
    imageContainer: {
        width: 125,
        height: 100,
        borderWidth: 5,
        borderRadius: '50%',
        borderColor: '#479199',
        borderStyle: 'solid',
        marginTop: -120,
        marginRight: 15,
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        borderRadius: '50%',
    },
    userInfoContainer: {
        width: '100%',
        maxWidth: '100%',
    },
    name: {
        fontSize: 25,
        fontWeight: 'bold',
        color: '#479199',
    },
    position: {
        fontSize: 14,
        marginTop: 5,
        color: '#d8a65e'
    },
    description: {
        textAlign: 'justify',
        lineHeight: 1.5,
        fontSize: 9,
        marginTop: 20,
        width: '100%',
        wordBreak: 'break-word',
    },
    contactInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    contactColumn: {
        flexDirection: 'column'
    },
    contactItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 7
    },
    contactIcon: {
        width: 13,
        height: 13,
        marginLeft: 15,
        marginRight: 5,
    },
    contactText: {
        fontSize: 9,
    },
    link: {
        color: 'black',
        textDecoration: 'none',
    },
    headlineContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
    },
    headlineStartPoint: {
        width: 10,
        height: 10,
        borderWidth: 2,
        borderColor: '#479199',
        borderRadius: 1,
        transform: 'rotate(45deg)',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 0,
    },
    headlineSmallStartPoint: {
        width: 5,
        height: 5,
        borderRadius: 1,
        backgroundColor: '#479199',
    },
    headlineCaption: {
        fontSize: 12,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginHorizontal: 10,
        color: '#479199',
    },
    headlineLineContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headlineLine: {
        height: 2,
        backgroundColor: '#479199',
        marginHorizontal: 0,
    },
    headlineSmallPoint: {
        width: 5,
        height: 5,
        borderRadius: 1,
        backgroundColor: '#479199',
        transform: 'rotate(45deg)',
    },
    skillsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: -20,
    },
    skillBox: {
        padding: 5,
        borderRadius: 4,
        marginRight: 5,
        marginBottom: 5,
        fontSize: 9,
        backgroundColor: '#479199',
        color: '#ffffff',
    },
    workExperienceContainer: {
        width: '100%',
        maxWidth: '100%',
        marginTop: -13,
        marginBottom: 8,
    },
    workExperienceTitle: {
        fontSize: 12,
        fontWeight: 'bold'
    },
    workExperienceProjectName: {
        fontSize: 10,
        marginTop: 5,
    },
    workExperienceProjectInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    workExperienceProjectInfoColumn: {
        flexDirection: 'column',
        color: '#d8a65e',
        fontSize: 8,
        fontStyle: 'italic',
        marginTop: 5,
    },
    workExperienceDescription: {
        textAlign: 'justify',
        lineHeight: 1.5,
        fontSize: 9,
        marginTop: 10,
        width: '100%',
        wordBreak: 'break-word',
    },
    workExperienceList: {
        paddingLeft: 10,
        marginTop: 10,
    },
    workExperienceItem: {
        fontSize: 9,
        marginBottom: 4,
    },
    workExperienceBulletPoint: {
        marginRight: 10,
    },
    workExperienceItemText: {
        textAlign: 'justify',
        lineHeight: 1.5,
        width: '100%',
        wordBreak: 'break-word',
    },
    educationContainer: {
        width: '100%',
        maxWidth: '100%',
        marginTop: -13,
        marginBottom: 8,
        marginHorizontal: 15
    },
    educationInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    educationColumn: {
        flexDirection: 'column',
    },
    educationDegree: {
        fontSize: 11,
        fontWeight: 'ultrabold',
    },
    educationUniversity: {
        fontSize: 9,
        marginTop: 6,
    },
    educationDate: {
        fontSize: 8,
        marginTop: 6,
        color: '#d8a65e',
    },
    langContainer: {
        width: '100%',
        maxWidth: '100%',
        marginTop: -13,
        marginBottom: 8,
    },
    langInfo: {
        flexDirection: 'row',
    },
    langColumn: {
        flexDirection: 'column',
        marginHorizontal: 15,
    },
    langTitle: {
        fontSize: 11,
        fontWeight: 'ultrabold',
    },
    langLevel: {
        fontSize: 8,
        marginTop: 6,
        color: '#d8a65e',
    },
});


export const ResumeDocument = () => {
    const experienceAge = calculateYearPeriod(process.env.REACT_APP_START_WORKING_DATETIME);

    const Headline = ({caption, lineWidth = 380}) => (
        <View style={styles.headlineContainer}>
            <View style={styles.headlineStartPoint}>
                <View style={styles.headlineSmallStartPoint} />
            </View>
            <Text style={styles.headlineCaption}>{caption}</Text>
            <View style={styles.headlineLineContainer}>
                <View style={styles.headlineSmallPoint} />
                <View style={[styles.headlineLine, {width: lineWidth}]} />
                <View style={styles.headlineSmallPoint} />
            </View>
        </View>
    );

    const Skills = () => (
        <View style={styles.skillsContainer}>
            <Text style={styles.skillBox}>Python (Django, Flask, Tkinter)</Text>
            <Text style={styles.skillBox}>PHP (Laravel, Lumen)</Text>
            <Text style={styles.skillBox}>JavaScript (React, jQuery)</Text>
            <Text style={styles.skillBox}>HTML / CSS</Text>
            <Text style={styles.skillBox}>Linux Bash</Text>
            <Text style={styles.skillBox}>MQL4 / MQL5 (MT4 / MT5)</Text>
            <Text style={styles.skillBox}>SQL (MySQL, PostgreSQL, Redis, MSSQL, MongoDB)</Text>
            <Text style={styles.skillBox}>Docker & Docker Compose</Text>
            <Text style={styles.skillBox}>CI / CD</Text>
            <Text style={styles.skillBox}>REST API / GraphQL</Text>
            <Text style={styles.skillBox}>DevOps (AWS, DigitalOcean, CloudFlare)</Text>
            <Text style={styles.skillBox}>Scrum, Agile</Text>
            <Text style={styles.skillBox}>Image Processing</Text>
            <Text style={styles.skillBox}>Machine Learning</Text>
        </View>
    );

    const WorkExperience = ({title, company, date, country, description, todo_list, description_extra=null}) => (
        <View style={styles.row}>
            <View style={styles.workExperienceContainer}>
                <Text style={styles.workExperienceTitle}>{title}</Text>
                <Text style={styles.workExperienceProjectName}>{company}</Text>
                <View style={styles.workExperienceProjectInfo}>
                    <Text style={styles.workExperienceProjectInfoColumn}>{date}</Text>
                    <Text style={styles.workExperienceProjectInfoColumn}>{country}</Text>
                </View>
                <Text style={styles.workExperienceDescription}>{description}</Text>
                <View style={styles.workExperienceList}>
                    {todo_list.map(function(todo) {
                        return (
                            <Text style={styles.workExperienceItem}>
                                <Text style={styles.workExperienceBulletPoint}>• </Text>
                                <Text style={styles.workExperienceItemText}>{todo}</Text>
                            </Text>
                        )
                    })}
                </View>
                {description_extra && (<Text style={styles.workExperienceDescription}>{description_extra}</Text>)}
            </View>
        </View>
    );

    const Education = ({degree, university, date}) => (
        <View style={styles.educationColumn}>
            <Text style={styles.educationDegree}>{degree}</Text>
            <Text style={styles.educationUniversity}>{university}</Text>
            <Text style={styles.educationDate}>{date}</Text>
        </View>
    );

    const LanguageSkill = ({title, level}) => (
        <View style={styles.langColumn}>
            <Text style={styles.langTitle}>{title}</Text>
            <Text style={styles.langLevel}>{level}</Text>
        </View>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Profile Section */}
                <View style={styles.row}>
                    <View style={styles.imageContainer}>
                        <Image
                            style={styles.image}
                            src={require('../../assets/img/avatar.JPG')}
                        />
                    </View>
                    <View style={styles.userInfoContainer}>
                        <Text style={styles.name}>{process.env.REACT_APP_PROJECT_NAME}</Text>
                        <Text style={styles.position}>{process.env.REACT_APP_META_TITLE}</Text>
                        <Text style={styles.description}>
                            I am a seasoned Python developer with over {experienceAge} years of experience in the web and application industry, proficient in frameworks such as Django and Flask. My profound interest in programming and developing intelligent applications led me to pursue a degree in Artificial Intelligence. This passion has fueled my involvement in numerous projects and collaborations with various companies, allowing me to gain extensive experience and expertise. I thrive in Agile environments and have successfully managed multiple projects.
                            My current focus is on utilizing AI to optimize processesand achieve superior outcomes, particularly in the fields of medicine and trading. My university thesis focused on the detection of epilepsy using hybrid methods, and I am presently engaged in a project aimed at predicting market movements.
                            Throughout my career, I have committed myself to learning multiple programming languages, deriving immense satisfaction from the learning process. Despite this, my primary focus remains on Python, driven by my strong passion for Artificial Intelligence.
                        </Text>
                    </View>
                </View>

                {/* Contact Information */}
                <View style={styles.contactInfo}>
                    <View style={styles.contactColumn}>
                        <View style={styles.contactItem}>
                            <Image
                                style={styles.contactIcon}
                                src={require('../../assets/img/resume/website.png')}
                            />
                            <Text style={styles.contactText}>
                                <Link src="https://imankarimi.com" style={styles.link}>
                                    www.imankarimi.com
                                </Link>
                            </Text>
                        </View>
                        <View style={styles.contactItem}>
                            <Image
                                style={styles.contactIcon}
                                src={require('../../assets/img/resume/email.png')}
                            />
                            <Text style={styles.contactText}>
                                <Link src="mailto:imankarimi.mail@gmail.com" style={styles.link}>
                                    imankarimi.mail@gmail.com
                                </Link>
                            </Text>
                        </View>
                        <View style={styles.contactItem}>
                            <Image
                                style={styles.contactIcon}
                                src={require('../../assets/img/resume/skype.png')}
                            />
                            <Text style={styles.contactText}>
                                <Link src="skype:imankarimi.mail" style={styles.link}>
                                    skype:imankarimi.mail
                                </Link>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.contactColumn}>
                        <View style={styles.contactItem}>
                            <Image
                                style={styles.contactIcon}
                                src={require('../../assets/img/resume/github.png')}
                            />
                            <Text style={styles.contactText}>
                                <Link src="https://github.com/imankarimi" style={styles.link}>
                                    github.com/imankarimi
                                </Link>
                            </Text>
                        </View>
                        <View style={styles.contactItem}>
                            <Image
                                style={styles.contactIcon}
                                src={require('../../assets/img/resume/linkedin.png')}
                            />
                            <Text style={styles.contactText}>
                                <Link src="https://linkedin.com/in/iman-karimi/" style={styles.link}>
                                    linkedin.com/in/iman-karimi/
                                </Link>
                            </Text>
                        </View>
                        <View style={styles.contactItem}>
                            <Image
                                style={styles.contactIcon}
                                src={require('../../assets/img/resume/stackoverflow.png')}
                            />
                            <Text style={styles.contactText}>
                                <Link src="https://stackoverflow.com/users/4057653/iman-karimi" style={styles.link}>
                                    stackoverflow.com/users/4057653/iman-karimi
                                </Link>
                            </Text>
                        </View>
                    </View>
                </View>

                {/* Technical Skills */}
                <View style={styles.row}>
                    <Headline caption="Technical Skills" />
                </View>
                <View style={styles.row}>
                    <Skills />
                </View>

                {/* Work Experience */}
                <View style={styles.row}>
                    <Headline caption="Work Experience" />
                </View>
                <WorkExperience
                    title="CTO & Python Developer & AI (Remote)"
                    company="Phownance: Advanced Forex Market Analysis Using AI"
                    date="Jul 2023 - Present"
                    country="USA, Los Angeles"
                    description="Phownance is an innovative and ongoing development project focused on the analysis and prediction of forex market movements. By leveraging artificial intelligence models, this project aims to identify key market indicators such as price corrections, support and resistance levels, and candlestick patterns:"
                    todo_list={[
                        "Research and development of various image processing methods and convolutional neural networks.",
                        "Discovery of patterns and methods providing high accuracy in market pattern identification.",
                        "High Accuracy in Market Predictions: 86.5% accuracy in detecting price corrections and 92% accuracy in identifying candlestick types.",
                        "Continuous improvement and fine-tuning of AI models and expanding datasets and integrating additional data such as trading volumes and economic news.",
                        "Utilizing Python and the Django framework for API development.",
                        "Employing MQL4 and MQL5 for algorithmic trading and integrating with MT5 and MT4 platforms for seamless trading execution.",
                        "Optimization of models for improved performance and development of visualization tools for results and analyses.",
                    ]}
                    description_extra="Phownance has successfully met its initial requirements and continues to evolve, aiming to become a powerful tool for forex market analysis and prediction through cutting-edge artificial intelligence and state-of-the-art technology."
                />
                <WorkExperience
                    title="CTO & Backend Developer & DevOps (Remote)"
                    company="ICodesWholesale (ICW)"
                    date="May 2019 - Present"
                    country="USA, Los Angeles"
                    description="ICodesWholesale (ICW) is a marketplace for selling digital gift cards in bulk. Based in Los Angeles, California. ICW is the only marketplace in the United States of America that specialized in selling digital and games gift cards at a wholesale price"
                    todo_list={[
                        "Managed tasks and assigned them to the desired people and create EPICs for time management.",
                        "Managed 3 developers of the team as a CTO based on business and project needs.",
                        "Completed full redesign of the web application to improve performance, and security by up to 70% and enhance daily active users by up to 90%.",
                        "Reviewed the codes and solved the problem of other developers codes.",
                        "Database designing by MySQL and using Redis for cache system, and developing the project by Python and Django REST framework.",
                        "Using Unit tests and API tests for accuracy in functionality and performance.",
                        "Working with AWS and using EC2 and RDS to run the project and also LightSail for the WP blog.",
                        "Using GitLab as a version controller and using CI/CD to deploy the project on test and master servers automatically.",
                    ]}
                />
                <WorkExperience
                    title="Backend Developer"
                    company="EZPIN"
                    date="Jan 2020 - Dec 2022"
                    country="Turkey"
                    description="EZPIN was created with a singular focus: To enable and leverage gift cards as powerful branded payment instruments to drive and grow revenues in-store, online, in digital wallets, via social media, in apps, and in games! Our global distribution channel and our comprehensive distribution panel enabled brands to reach to thousands of clients across the globe."
                    todo_list={[
                        "Database designing by MySQL and using Redis for cache system.",
                        "Develop the project by Python and Django REST framework.",
                        "Improve performance, and security by up to 60% and enhance daily active users by up to 80%.",
                        "Reviewed the codes and solved the problem of other developers codes.",
                        "Trained 3 developers of the team to develop ongoing projects and maintain developed projects.",
                        "Using Unit test and API test for accuracy in functionality and performance.",
                        "Using GitLab as a version controller and using CI/CD to deploy the project on test and master servers automatically.",
                        "Increased APIs performance by up to 70% to meet specific requirements, such as quick response with caching the responses.",
                        "Design, implementation, and coding all services related to this project (EZPIN), such as EZPIN CRM (Form builder) service and BTC services for regular customers such as eGift Broker.",
                    ]}
                />
                <WorkExperience
                    title="Backend Developer (Remote)"
                    company="AppSeed"
                    date="Feb 2020 - Jan 2022"
                    country="Romania, Constanta"
                    description="AppSeed basically aims to cut the manual work usually involved in web development using automation tools and boilerplate code. When a new project is started, the developer does this repetitive work over and over: Integrate the UI (this takes days to extract the layouts, components..), Code the basic modules, using the best practices, Prepare the app with necessary scripts and configuration to be deployed LIVE. The value-added provided by AppSeed it’s a sum-up of all those steps. The flat, lifeless UI is automatically processed using an HTML Parser (in-house developed tool) that prepares the UI files to become production-ready for many technologies: PHP, Python, React, Vue."
                    todo_list={[
                        "Converting UI kits to Django packages. These packages change the appearance of the Django admin based on the desired UI kit.",
                        "Package optimization to support all versions of Django.",
                        "Develop ongoing projects and maintain developed projects and support customers to solve problems and better implementation.",
                    ]}
                />
                <WorkExperience
                    title="Backend Developer"
                    company="Yara"
                    date="Feb 2017 - Dec 2022"
                    country="Iran, Tehran"
                    description="Yara Group started its activity in 2003 and is considered one of the leading companies in the field of production and development of games and mobile applications. The Yara collection has produced more than 30 applications, among which we can mention the games Khoros Jangi (Rooster), Zero to Hundred, Footcardia, as well as Zaafron, Bank Branches, Fajr Festival, Pishvaz Irancell, Tapligh, and Digitoon."
                    todo_list={[
                        "Working on Tapligh project, Tapligh is An online advertising network or ad network that connects advertisers to websites that want to host advertisements. The key function of an ad network is an aggregation of ad space supply from publishers and matching it with advertiser demand.",
                        "Database designing by MySQL and using Redis for cache system.",
                        "Develop the project by Python (Django REST framework) and PHP (Laravel and Lumen).",
                        "Improve performance, and security by up to 80% and enhance daily active users by up to 90%.",
                        "Reviewed the codes and solved the problem of other developers codes.",
                        "Trained 5 developers of the team to develop ongoing projects and maintain developed projects.",
                        "Using Unit test and API test for accuracy in functionality and performance.",
                        "Completed full redesign of the web application to improve performance with PHP and Python frameworks, and security by up to 70% and enhance daily active users by up to 90%.",
                        "Design and code Unity 3D SDK for use in mobile games (coding a wrapper to use android SDK in Unity 3D).",
                    ]}
                />
                <WorkExperience
                    title="Backend Developer"
                    company="Homatec"
                    date="Sep 2016 - Jan 2017"
                    country="Iran, Tehran"
                    description="Homatec is a company with a demonstrated history of working in web-related services, among which we can mention the Yaghoot Rooyan web application. Yaghoot Rooyan International Group was founded in 1991 aiming to import food supplements, pharmaceuticals, medical equipment, and also herbal hygienic and cosmetic products for caring for and protecting public health and hair and skin beauty."
                    todo_list={[
                        "Develop the projects by PHP (Laravel and Lumen and Jumla platform) and database designing by MySQL.",
                        "Improve performance, and security by up to 60% and enhance daily active users by up to 70%.",
                    ]}
                />
                <WorkExperience
                    title="Backend Developer"
                    company="SRICO"
                    date="Sep 2015 - Aug 2016"
                    country="Iran, Tehran"
                    description="SRICO is a company with a demonstrated history of working in the web-related services and applications industry, among which we can mention the Khabargram (Khabargram is a news crawler that brings together all the news of the day and of the moment to all the important news agencies and presents them to the enthusiasts. The feature of this software is that you do not need to spend your time surfing on different sites.), Exchange brokerage group (The web-based brokerage software is one of the most successful web application in the field of stock exchange, which allows its users to analyze and forecast the results of the stock exchange.), Distribution and allocation of water resources (The system supporting the distribution and allocation of water resources in Qazvin Plain), and Dast2."
                    todo_list={[
                        "Develop the projects by PHP (Laravel and Lumen Framework) and database designing by MySQL.",
                        "DB Query optimization and cache system to fast response.",
                        "Improve performance, and security by up to 80% and enhance daily active users by up to 70%.",
                        "Coding a crawler engine to crawl websites contents using PHP simple HTML dom and using data mining algorithms to detect duplicate contents.",
                    ]}
                />
                <WorkExperience
                    title="Backend Developer (Remote)"
                    company="WeNews"
                    date="Jan 2014 - Nov 2015"
                    country="Iran, Tehran"
                    description="WeNews is a news crawler that brings together all the news of the day and of the moment to all the important news agencies and presents them to enthusiasts."
                    todo_list={[
                        "Develop the projects by PHP (Pure, Laravel, and Lumen Framework) and database designing by MySQL.",
                        "DB Query optimization and cache system to fast response.",
                        "Coding a crawler engine to crawl websites contents using PHP simple HTML dom and using Datamining algorithms to detect duplicate contents.",
                    ]}
                />

                {/* Education */}
                <View style={styles.row}>
                    <Headline caption="Education" lineWidth="450" />
                </View>
                <View style={styles.row}>
                    <View style={styles.educationContainer}>
                        <View style={styles.educationInfo}>
                            <Education
                                degree="MS. in Artificial Intelligence"
                                university="Shafagh University of Nashtarood"
                                date="Sep 2017 - Sep 2020"
                            />
                            <Education
                                degree="BS. in Software Engineering"
                                university="PNU University of Ramsar"
                                date="Sep 2012 - Jun 2017"
                            />
                        </View>
                    </View>
                </View>

                {/* Language */}
                {/*<View style={styles.row}>*/}
                {/*    <Headline caption="Language" lineWidth="450" />*/}
                {/*</View>*/}
                {/*<View style={styles.row}>*/}
                {/*    <View style={styles.langContainer}>*/}
                {/*        <View style={styles.langInfo}>*/}
                {/*            <LanguageSkill title="English" level="Fluent" />*/}
                {/*            <LanguageSkill title="Persian" level="Native" />*/}
                {/*        </View>*/}
                {/*    </View>*/}
                {/*</View>*/}
            </Page>
        </Document>
    );
}
