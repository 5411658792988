import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {PDFDownloadLink} from '@react-pdf/renderer';
import {ResumeDocument} from "../PDFTemplates/resume";


export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const mobileMenuHide = (e) => {
        e.preventDefault();
        let windowWidth = window.innerWidth;

        if (!isMenuOpen && windowWidth < 1025) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    return (
        <>
            <header id="site_header" className={isMenuOpen ? "header animate" : "header animate mobile-menu-hide"}>
                <div className="header-content">
                    <div className="header-photo">
                        <img
                            src={require('../../assets/img/avatar.JPG')}
                            alt="Iman Karimi"
                        />
                    </div>
                    <div className="header-titles">
                        <h2>Iman Karimi</h2>
                        <h4>{process.env.REACT_APP_META_TITLE}</h4>
                    </div>
                </div>

                <ul className="main-menu">
                    <li onClick={mobileMenuHide}>
                        <Link
                            to="/"
                            className={`nav-anim ${location.pathname === "/" ? "active" : ""}`}
                        >
                            <span className="menu-icon lnr lnr-home"></span>
                            <span className="link-text">Home</span>
                        </Link>
                    </li>
                    <li onClick={mobileMenuHide}>
                        <Link
                            to="/about"
                            className={`nav-anim ${location.pathname === "/about" ? "active" : ""}`}
                        >
                            <span className="menu-icon lnr lnr-user"></span>
                            <span className="link-text">About Me</span>
                        </Link>
                    </li>
                    <li onClick={mobileMenuHide}>
                        <Link
                            to="/resume"
                            className={`nav-anim ${location.pathname === "/resume" ? "active" : ""}`}
                        >
                            <span className="menu-icon lnr lnr-graduation-hat"></span>
                            <span className="link-text">Resume</span>
                        </Link>
                    </li>
                    <li onClick={mobileMenuHide}>
                        <Link
                            to="/blog"
                            className={`nav-anim ${location.pathname === "/blog" ? "active" : ""}`}
                        >
                            <span className="menu-icon lnr lnr-book"></span>
                            <span className="link-text">Blog</span>
                        </Link>
                    </li>
                    <li onClick={mobileMenuHide}>
                        <Link
                            to="/contact"
                            className={`nav-anim ${location.pathname === "/contact" ? "active" : ""}`}
                        >
                            <span className="menu-icon lnr lnr-envelope"></span>
                            <span className="link-text">Contact Me</span>
                        </Link>
                    </li>
                </ul>

                <div className="social-links">
                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/in/iman-karimi/" target="_blank">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://github.com/imankarimi" target="_blank">
                                <i className="fab fa-github"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://stackoverflow.com/users/4057653/iman-karimi" target="_blank">
                                <i className="fab fa-stack-overflow"></i>
                            </a>
                        </li>
                        <li>
                            <a href="skype:imankarimi.mail">
                                <i className="fab fa-skype"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="header-buttons">
                    <PDFDownloadLink
                        className="btn btn-primary"
                        document={<ResumeDocument />}
                        fileName="ImanKarimi_CV.pdf"
                    >
                        {({ loading }) => (loading ? 'Loading CV...' : 'Download CV')}
                    </PDFDownloadLink>
                </div>

                <div className="copyrights">© 2024 All rights reserved.</div>
            </header>

            <div className={isMenuOpen ? "menu-toggle open" : "menu-toggle"} onClick={mobileMenuHide}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </>
    );
}